import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import moment from "moment";
import { isArray } from "lodash";

const initialState = {
	userData: {
		id: "", 
		email: "", 
		name: "", 
		access_type: "",
		is_2fa_active: false, 
		_2fa_frequency: "",
		cust_info: {},
		user_type: 'user',
		main_user_type:'',
		payment_overdue : false,
		checked_browser_setting : false,
	},
	isProcessingLease: false,
	isProcessingBatch: false,
	isProcessingSearch: false,
	changeVersion : false,
	version: 0,
	clientModules: {},
	mode:'lessee',
	onLogout: async ()=> {},
	reloadUserData: async (credentials) => {},
	reloadProcessingStatus: async () => {},
	reloadProcessingBatches: async () => {},
	getProcessingStatus: async () => {},
	reloadProcessingSearch: async () => {},
	getProcessingSearch: async () => {},
	getProcessingBatch: async () => {},
	getAllClients: async () => {},
	changeMode: async (mode) => {},
	loading: false
};

const AuthContext = React.createContext(initialState);

const AuthProvider = ({ children }) => {
	const [userData, setUserData] = useState({
		id: "", 
		email: "", 
		name: "", 
		access_type: "",
		is_2fa_active: false, 
		_2fa_frequency: "",
		cust_info: {},
		user_type: 'user',
		main_user_type:'',
		payment_overdue : false,
		checked_browser_setting : false,
	});
	const [loading, setLoading] = useState(true);
	const [isProcessingLease, setIsProcessingLease] = useState(false);
	const [isProcessingBatch, setIsProcessingBatch] = useState(false);
	const [isProcessingSearch, setIsProcessingSearch] = useState(false);
	const [clientModules, setClientModules] = useState({});
	const [mode, setMode] = useState('lessee');
	const [changeVersion, setChangeVersion] = useState(false);
	const [version, setVersion] = useState(0);

	const onLogout = async () => {
		try{
			const response = await axios.get('https://api.ipify.org?format=json')
			await axios.post(`${AppConfig.baseUrl}/users/update_last_logout`, {date: new Date(), ip: (response && response.data && response.data.ip) || ''}, {
                headers: { token: localStorage.getItem("token") }
            });
		}catch(error){
		  console.log(error)
		}
		setUserData(initialState.userData);
		localStorageClear()
		sessionStorage.clear();
		window.location.href = "/login"
	};

	function localStorageClear() {    
		localStorage.removeItem("last_active")
		localStorage.removeItem("company")
		localStorage.removeItem("token")
		localStorage.removeItem("temp_token")
	}

	const getVersion = async()=>{
		try {
			const {data} = await axios.get(`${AppConfig.baseUrl}/users/get_version?type=customer`);
			if(!data.error){
				if(data.versions.version != localStorage.getItem("version")){
					setChangeVersion(true)
					setVersion(data.versions.version)
				}
			}
			else{
				throw new Error(data.title);
			}
		} catch (error) {
			toast.error(error.message || 'Something went wrong')
		}
	}
	useEffect(() => {
		getVersion()
	},[])

	const reloadUserData = async () => {
		try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/users/token/refresh`, null, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                localStorage.setItem("token", data.token);
                const payload = data.tokenData
                setUserData({
                    id: payload.user_id,
                    name: payload.name,
                    email: payload.email,
					company: payload.company,
					access_type: payload.access_type,
                    is_2fa_active: payload.is_2fa_active,
					_2fa_frequency: payload._2fa_frequency,
					cust_info: payload.cust_info,
					main_user_type: payload.main_user_type,
					payment_overdue : payload.payment_overdue,
					checked_browser_setting : payload.checked_browser_setting,
                });
            }
            else{
                throw new Error(data.title);
            }
		} catch(e) {
            onLogout();
        }

		setLoading(false);
	};

	const reloadProcessingStatus = async() => {
		try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/lease/getProcessingLeases`,{mode: mode ? mode : 'lessee'}, {
                headers: { token: localStorage.getItem("token") }
            });
			if((data.leaseData && data.leaseData.length > 0) || (data.expiredLeases && data.expiredLeases.length > 0) || (data.expEdits && data.expEdits.length > 0)){
				if(String(window.location.href).endsWith(`/leases`)){
					window.location.href = `/${localStorage.getItem('company')}/leases`
				}
			}
			if(data.leaseData && data.leaseData.length > 0){
				data.leaseData.map(v => {
					if(window.location.href.includes(`/leases/details/${v._id}`)){
						window.location.href = `/${localStorage.getItem('company')}/leases/details/${v._id}/refresh`
					}
				})
			}
			if(data.expiredLeases && data.expiredLeases.length > 0){
				data.expiredLeases.map(v => {
					if(window.location.href.includes(`/leases/details/${v._id}`)){
						window.location.href = `/${localStorage.getItem('company')}/leases`
					}
				})
				const leaseNames = data.expiredLeases.map(v => v.name)
				toast.error(`Your leases with names "${leaseNames.join(', ')}" could not be added.`)
			}
			if(data.expEdits && data.expEdits.length > 0){
				data.expEdits.map(v => {
					if(window.location.href.includes(`/leases/details/${v._id}`)){
						window.location.href = `/${localStorage.getItem('company')}/leases/details/${v._id}/refresh`
					}
				})
				const leaseNames = data.expEdits.map(v => v.name)
				toast.error(`Your leases with names "${leaseNames.join(', ')}" could not be edited.`)
			}

			if(data.processingCount > 0){
				setIsProcessingLease(true)
			}else{
				setIsProcessingLease(false)
			}
		} catch (error) {
			console.log('Error getting processing leases: ', error);
		}
	}

	const getProcessingStatus = async() =>{
		const { data } = await axios.post(`${AppConfig.baseUrl}/lease/getProcessingLeases`, {mode: mode ? mode : 'lessee'}, {
			headers: { token: localStorage.getItem("token") }
		});
		if(data.processingCount > 0){
			setIsProcessingLease(true)
		}
		else{
			setIsProcessingLease(false)
		}
	}

	const reloadProcessingBatches = async() => {
		try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/bulk_lease/getProcessingBatches`,{mode: mode ? mode : 'lessee'}, {
                headers: { token: localStorage.getItem("token") }
            });
			if(data.processedBatch && data.processedBatch.length > 0){
				const batches = data.processedBatch.map(v => v.batch_no)
				toast.success(`Your leases of batch no "${batches.join(', ')}" are created successfully.`)
				await new Promise(resolve => setTimeout(resolve,1000))
				if(String(window.location.href).endsWith(`/bulk_upload`)){
					window.location.href = `/${localStorage.getItem('company')}/bulk_upload`
				}
				data.processedBatch.map(v => {
					if(window.location.href.includes(`/bulk_upload/details/${v._id}`)){
						window.location.href = `/${localStorage.getItem('company')}/bulk_upload/details/${v._id}`
					}
				})
			}

			if(data.is_processing){
				setIsProcessingBatch(true)
			}else{
				setIsProcessingBatch(false)
			}
		} catch (error) {
			console.log('Error getting processing leases: ', error);
		}
	}

	const getProcessingBatch= async() =>{
		const { data } = await axios.post(`${AppConfig.baseUrl}/bulk_lease/getProcessingBatches`, {mode: mode ? mode : 'lessee'}, {
			headers: { token: localStorage.getItem("token") }
		});
		if(data.is_processing){
			setIsProcessingBatch(true)
		}
		else{
			setIsProcessingBatch(false)
		}
	}

	const getAllClients = async() =>{
		setClientModules({});
		setLoading(true);
		try {
			const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}`,{
				headers: { token: localStorage.getItem("token") }
			});
			if(!data.error){
				if(data.clientData && isArray(data.clientData) && data.clientData.length > 0){
					let moduleData = {}
					for(const cl of data.clientData){
						moduleData = {...moduleData, [String(cl.client_id._id)]: cl.client_id.modules}
					}
					setClientModules(moduleData)
				}
			}
			else{
				throw new Error(data.title);
			}
		} catch (error) {
			toast.error(error.message || 'Something went wrong')
		}
		setLoading(false)
	}

	const reloadProcessingSearch = async() => {
		try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/search_query/getProcessingSearches`, {mode: mode ? mode : 'lessee'}, {
                headers: { token: localStorage.getItem("token") }
            });
			if(data.processingCount > 0){
				setIsProcessingSearch(true)
				if(data.searchData){
					toast.success(<a style={{color: '#006644'}} href={`/${localStorage.getItem('company')}/${data.searchData.type=='journal_entries'? 'journal' : data.searchData.type=='mapped_je' ? 'gl_mapped' : data.searchData.type}?id=${data.searchData._id}`}>
						Your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type=='mapped_je' ? 'G/L map journal entries' : data.searchData.type} for {data.searchData.lease_ids.length} leases {data.searchData.type == 'reports' && data.searchData.report_type != 'rollforward' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`} are ready. Please click here to see details.
					</a>)
					if(data.expiredSearch){
						toast.error(<>
							We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type=='mapped_je' ? 'G/L map journal entries' : data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' && data.searchData.report_type != 'rollforward' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
						</>)
					}
				}
				else if(data.expiredSearch){
					toast.error(<>
						We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type=='mapped_je' ? 'G/L map journal entries' : data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' && data.searchData.report_type != 'rollforward' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
					</>)
				}
			}
			else if(data.searchData){
				setIsProcessingSearch(false)
				toast.success(<a style={{color: '#006644'}} href={`/${localStorage.getItem('company')}/${data.searchData.type=='journal_entries' ? 'journal' : data.searchData.type=='mapped_je' ? 'gl_mapped' : data.searchData.type}?id=${data.searchData._id}`}>
					Your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type=='mapped_je' ? 'G/L map journal entries' : data.searchData.type} for {data.searchData.lease_ids.length} leases {data.searchData.type == 'reports' && data.searchData.report_type != 'rollforward' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`} are ready. Please click here to see details.
				</a>)
				if(data.expiredSearch){
					toast.error(<>
						We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type=='mapped_je' ? 'G/L map journal entries' : data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' && data.searchData.report_type != 'rollforward' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
					</>)
				}
			}
			else if(data.expiredSearch){
				setIsProcessingSearch(false)
				toast.error(<>
					We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type=='mapped_je' ? 'G/L map journal entries' : data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' && data.searchData.report_type != 'rollforward' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
				</>)
			}
			else{
				setIsProcessingSearch(false)
			}
		} catch (error) {
			console.log('Error getting processing leases: ', error);
		}
	}

	const getProcessingSearch = async() =>{
		const { data } = await axios.post(`${AppConfig.baseUrl}/search_query/getProcessingSearches`, {mode: mode ? mode : 'lessee'}, {
			headers: { token: localStorage.getItem("token") }
		});
		if(data.processingCount > 0){
			setIsProcessingSearch(true)
		}
		else{
			setIsProcessingSearch(false)
		}
	}
    const changeMode = async(mode)=>{
		setMode(mode)
	}
	useEffect(() => {
		const verifyToken = async () => {
			const temp_token = localStorage.getItem("temp_token")
			setLoading(true);
			try {
				const { data } = await axios.post(`${AppConfig.baseUrl}/users/token`, null, {
					headers: { token: localStorage.getItem("token") }
				});
                if(!data.error){
                    const payload = data.tokenData
                    setUserData({
						id: payload.user_id,
						name: payload.name,
						email: payload.email,
						company: payload.company,
						access_type: payload.access_type,
						is_2fa_active: payload.is_2fa_active,
						_2fa_frequency: payload._2fa_frequency,
						cust_info: payload.cust_info,
						main_user_type: payload.main_user_type,
						payment_overdue : payload.payment_overdue,
						checked_browser_setting : payload.checked_browser_setting,
                    });
                }
                else{
                    throw new Error(data.title);
                }
			} catch (e) {
				console.log('Error in auth', e)
                onLogout()
			}
			setLoading(false);
			localStorage.setItem('temp_token', temp_token)
		};

		if (window.location.pathname !== "/login") {
			verifyToken();
			getProcessingStatus();
			getProcessingSearch();
			getProcessingBatch();
		}
	}, [window.location.pathname]);

	useEffect(() => {
		if(userData.id){
			getAllClients();
		}
		else{
			setClientModules({})
		}
	},[userData])

	useEffect(()=>{
		const localMode = localStorage.getItem('mode')
		setMode(localMode ? localMode : 'lessee')
	},[])

	const value = {
		userData,
		onLogout,
		reloadUserData,
		loading,
		reloadProcessingStatus,
		isProcessingLease,
		getProcessingStatus,
		reloadProcessingBatches,
		isProcessingBatch,
		getProcessingBatch,
		getProcessingSearch,
		reloadProcessingSearch,
		isProcessingSearch,
		getAllClients,
		clientModules,
		mode,
		changeMode,
		changeVersion,
		setChangeVersion,
		version
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

AuthProvider.propTypes = {
    children: PropTypes.any,
};

export { AuthProvider, AuthConsumer, AuthContext };
