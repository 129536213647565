import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import AppConfig from "constants/config";
import axios from 'axios';
import {Card,CardBody,Col,Container,Row,Input,Spinner} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumbclientdetails"
import "assets/scss/manual/manual.scss"
import { AuthContext } from "context/authContext";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";
import moment from "moment";
import toast from "react-hot-toast";

const getActivity = (d)=>{
    if(d == "add"){ return "Lease added"}
    else if(d == 'edit'){ return "Lease edited"}
    else if (d == 'clone') {return "Lease cloned"}
    else if (d == 'draft') {return "Lease saved as draft"}
    else if (d == 'terminate') {return "Lease terminated"} 
    else if (d == 'modify') { return "Lease modified"} 
    else if (d == 'impairment') { return "Impairment added"}
    else if (d == 'submit') { return "Lease submitted"} 
    else if (d == 'review') { return "Lease reviewed"}
    else if (d == 'delete') { return "Lease deleted"}
    else if (d == 'active') { return "Lease turned active"}
    else if (d == 'inactive') { return "Lease turned inactive"}
    else if (d == 'client_change') { return "Client changed"}
    else if (d == 'revert') {return "Lease reverted"} 
    else if (d == 'useful_life') { return "Change in Useful Life updated"}
    else if (d == 'process') { return "Variable payment processed"}
    else if (d == 'bulk_lease') { return "Bulk uploaded"}
}

const ActivityLog = () => {
  const params = useParams();
  const [selectedOptions, setSelectedOption] = useState("");
  const [activityLogData, setActivityLogData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lease,setLease] = useState([]);
  const {mode} = useContext(AuthContext)
  const [originalData, setOriginalData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const activitylogColumns = [
    {
      name: "lease_name",
      label: "Lease name",
    },
    {
      name: "activity",
      label: "Narration",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '150px',height: '40px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        customBodyRender:(data)=>(
          <div>
            {getActivity(data)}
          </div>
        ),
      }
    },
    {
      name: "createdAt",
      label: "Date Of Activity",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(data)=>(
          <div>
            {moment(data).format('LLLL')}
          </div>
        ),
      }
    },
    {
      name:"user_name",
      label: "User",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({style: {minWidth: '150px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
      }
    }
  ]

  const handleChangeLeaseType = (e) => {
    setSelectedOption(e.target.value)
    getActivitys({lease_name: e.target.value})
  }

  useEffect(()=>{
    let ldata = []
    if(activityLogData && activityLogData.length>0 && selectedOptions == ''){
      for(const a of activityLogData){
        const data = ldata.filter(l => l == a.lease_name)
        if(data.length<1){
          ldata.push(a.lease_name)
        }
      }
    }else if(selectedOptions != ''){
      ldata=lease
    }
    setLease(ldata)
  },[activityLogData])

  useEffect(()=>{
    getActivitys('');
    getClientData();
  },[])

  const getActivitys = async (filter) => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/activity/list_activity?client_id=${params.id}&lease_name=${filter.lease_name || ''}&mode=${mode ? mode : 'lessee'}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        setActivityLogData(data.data)
        setOriginalData(data.data)
      } else {
        toast.error(data.title);
        navigate(`/${localStorage.getItem('company')}/clients`);
      }
    } catch (error) {    }
    setLoading(false)
  }

  const getClientData = async(req, res) => {
    try { 
      const clientD = await axios.get(`${AppConfig.baseUrl}/client/details?id=${params.id}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!clientD.data.error) {
        setClientData(clientD.data.clientDetails)
      }
    } catch (err) {

    }
  }

  useEffect(() => {
    if(mode && clientData && clientData.client_type && mode != clientData.client_type){
        window.location.href = `/${localStorage.getItem('company')}/clients`
    }
  },[mode])

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.lease_name.match(new RegExp(value,"i")))
    setActivityLogData(data)
  }

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>Client-details | LeaseJava</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs title="activitylog" breadcrumbItem={`${clientData?.client} - Activity log`} image={clientData?.logo} type="activitylog"/> 
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ThemeProvider theme={createTheme({components:getMuiTheme()})}>
                    <MUIDataTable
                      title={
                        <Row className="row">
                          <Col md="4" className="search-p">
                            <div className="me-2 mb-3">
                              <div className="position-relative sw">
                              <Input
                                  placeholder="Search"
                                  type="text"
                                  value={search}
                                  onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                              />
                              </div>
                            </div>
                          </Col>
                          <Col md="4" className="dropdown-p">
                            <div className="mb-3">
                              <select
                                value={selectedOptions}
                                onChange={handleChangeLeaseType}
                                id="formrow-InputState"
                                className="form-control"
                              >
                                <option name="lease" value="">Filter by Lease</option>
                                {lease.map( l => <option key={l} value={l}>{l}</option>)}
                              </select>
                            </div>
                          </Col>
                        </Row>
                      }
                      data={activityLogData}
                      columns={activitylogColumns}
                      options={{ 
                        pagination: false,
                        print:false, 
                        download:true, 
                        viewColumns: false,
                        filter:false, 
                        selectableRows:"none", 
                        search:false, 
                        rowsPerPageOptions:[], 
                        responsive:'scroll',
                        textLabels: {
                          body: {
                            noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                          },
                        },
                        downloadOptions:{
                          filename:'activity_log.csv'
                        },
                        onDownload:( buildHead, buildBody, columns, data) =>{
                          try{
                            const formattedData = []
                            for(const [index,row] of data.entries()){
                              const newRow = [ ...row.data ];
                              newRow[1] = getActivity(newRow[1])
                              newRow[2] = moment.utc(newRow[2]).format('LLLL')
                              formattedData.push({ ...row, data: newRow })
                            }
                            const csv = buildHead(columns) + buildBody(formattedData);
                            return csv
                          }catch(err){
                            console.log(err)
                            return buildHead(columns) + buildBody(data);
                          }                              
                        }
                      }}
                    />
                  </ThemeProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}


export default ActivityLog
