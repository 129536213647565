/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types'
import React, { useEffect, useState, useContext } from "react"
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Card,
  CardBody,
  Col,
  Progress,Tooltip
} from "reactstrap"
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import DeleteLease from "pages/Leases/deleteLease";
import TerminateLease from "pages/Leases/terminateLease";
import AddClientToLease from "pages/Leases/addClientToLease";
import ModifyDate from "pages/Leases/modifyDate";
import RevertLease from "pages/Leases/revertLease";
import Impairment from "pages/Leases/impairment";
import EditNonAccounting from "pages/Leases/editNonAccounting";
import toast from "react-hot-toast";
import axios from 'axios'
import AppConfig from 'constants/config'
import { AuthContext } from 'context/authContext'
import AddVariablePayment from '../../pages/Leases/addVariablePayment'
import ShortTermLease from "pages/Leases/shortTermLease";
import { get } from 'helpers/axios_helper';
import UsefulLife from "pages/Leases/usefulLife";

const CardGrid = props => {
  const { mode } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { lease, getLeaseData, clientAccess } = props
  const [progress, setProgress] = useState(0);
  const [selectedLease, setSelectedLease] = useState({})
  const [addClientLeasePopup, setAddClientLeasePopup] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [modifyOpen, setModifyOpen] = useState(false);
  const [terminateOpen, setTerminateOpen] = useState(false);
  const [revertOpen, setRevertOpen] = useState(false);
  const [impairment, setImpairment] = useState(false);
  const [editNonAccounting, setEditNonAccounting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variablePaymentOpen, setVariablePaymentOpen] = useState(false)
  const [variablePaymentArr, setVariablePaymentArr] = useState([])
  const [termiDate, setTermiDate] = useState('');
  const [loadingTerminate, setLoadingTerminate] = useState(false)
  const [shortTermOpen, setShortTermOpen] = useState(false);
  const [shortTerm, setShortTerm] = useState(false)
  const [usefulLife, setUsefulLife] = useState(false);
  const [tooltipId, setTooltipId] = useState('')

  const toggleUseful = () => {
    setUsefulLife(!usefulLife)
  }

  const toggleVariablePayment = () => {
    setVariablePaymentOpen(!variablePaymentOpen)
    setSelectedLease({})
  }

  const AddClientLeaseToggle = () => {
    setAddClientLeasePopup(!addClientLeasePopup)
  }

  const EditNonAccountingToggle = () => {
    setEditNonAccounting(!editNonAccounting)
    setSelectedLease({})
  }

  const toggleTerminateLease = () => {
    setTerminateOpen(!terminateOpen)
  }

  useEffect(() => {
    const val = progressBarForLeases(lease)
    setProgress(val);
  }, [lease])

  const progressBarForLeases = (data) => {
    let today = moment(new Date(), 'M/D/YYYY')
    let start = moment(new Date(data.commencement), 'M/D/YYYY')
    let end = moment(new Date(data.terminal_end_date), 'M/D/YYYY')
    let startEndDiff = end.diff(start, 'days');
    let todayEndDiff = end.diff(today, 'days');
    let per = ((startEndDiff - todayEndDiff) / startEndDiff) * 100;
    return Math.round(per)
  }

  const toggleDeleteLease = () => {
    setDeleteOpen(!deleteOpen)
  }

  const toggleModifyLease = () => {
    setModifyOpen(!modifyOpen)
  }

  const toggleRevertLease = () => {
    setRevertOpen(!revertOpen)
  }

  const toggleImpairment = () => {
    setImpairment(!impairment)
  }

  const deleteL = async (d) => {
    setLoading(true)
    try {
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease/delete_lease`, { Id: d, mode: mode ? mode : 'lessee' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        toast.success(data.title || 'Lease deleted successfully')
        getLeaseData({})
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
    toggleDeleteLease();
    setLoading(false)
  }

  const revertL = async (d) => {
    setLoading(true)
    try {
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease_draft/revertLease`, { lease_id: d, mode: mode ? mode : 'lessee',type:'revert' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        toast.success(data.title || 'Lease reverted successfully')
        getLeaseData({})
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
    toggleRevertLease();
    setLoading(false)
  }

  const modifyL = (data) => {
    let a = new Date(data.date)
    window.location.href =`/${localStorage.getItem('company')}/leases/modify?lease_id=${data.lease_id}&client_id=${data.client_id}&m_date=${data.date}&mod_type=${data.mod_type}&percentage=${data.percentage}`
  }

  const terminateL = async (d) => {
    setLoadingTerminate(true)
    try {
      let newDate = moment(d.date).format('YYYY-MM-DD');
      let amt = d.check === true ? Number(d.amount) : 0;
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease/terminate_lease`, { lease_id: d.lease_id, date: newDate, amount: amt, mode: mode ? mode : 'lessee' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        toast.success(data.title || 'Lease terminated successfully')
        navigate(`/${localStorage.getItem('company')}/leases/details/${data.lease_id}/refresh`);
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
    toggleTerminateLease();
    setLoadingTerminate(false)
  }

  useEffect(() => {
    if (selectedLease) {
        setTermiDate(moment(selectedLease.commencement).utc().add(1, 'day').format('MM/DD/YYYY'));
    }        
  },[selectedLease])

  useEffect(() => {
    if(lease){
      getModifyLease()
    }
  },[lease])

  const getModifyLease = async () => {
    try {
      let value = await get(`${AppConfig.baseUrl}/lease/get_modify_lease?lease_id=${lease._id}&mode=${mode}`,{
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      })
      if (value) {
        const leaseData = value.modLeaseData
        if(leaseData && leaseData.length > 0){
          if((moment(lease.terminal_end_date).diff(moment(leaseData[0].terminal_end_date),'months') < 12) && leaseData[0].is_short_term){
              setShortTerm(true)
          }else{
              setShortTerm(false)
          }
        } else{
            if(moment(lease.terminal_end_date).diff(moment(lease.commencement),'months') < 12){
                setShortTerm(true)
            }else{
                setShortTerm(false)
            }
        }
      }
    } catch (err) {
      console.log('error in listing lease ->', err)
    }
  }

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card style={{ backgroundColor: lease.is_processing ? '#faf1d7' : '#fff' }}>
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {!lease.client_id.logo ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="avatar-sm">
                      <div className="avatar-title rounded-circle">
                        {lease.client_id.client.charAt(0)}
                      </div>
                    </div>
                    <div className="client">
                      {
                        (lease.client_id.client).length > 11 ?
                          <span className="d-inline-block" tabIndex="0" dataToggle="tooltip" title={lease.client_id.client}>
                            <h5 className="text-dark text-truncate font-size-15 client-name">{lease.client_id.client}</h5>
                          </span>
                          :
                          <h5 className="text-dark text-truncate font-size-15">{lease.client_id.client}</h5>
                      }
                      <div style={clientAccess[lease.client_id.client] === "read_only" ? { color: 'orange', fontWeight: 'bold' } : { color: 'green', fontWeight: 'bold' }}>{lease && lease.client_id && clientAccess[lease.client_id.client] ? clientAccess[lease.client_id.client].replace('_', " ").toUpperCase() : ''}</div>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="avatar-sm">
                      <img
                        className="rounded-circle avatar-sm"
                        src={lease.client_id.logo}
                        alt=""
                      />
                    </div>
                    <div className="client">
                      {
                        (lease.client_id.client).length > 11 ?
                          <span className="d-inline-block" tabIndex="0" dataToggle="tooltip" title={lease.client_id.client}>
                            <h5 className="text-dark text-truncate font-size-15 client-name">{lease.client_id.client}</h5>
                          </span>
                          :
                          <h5 className="text-dark text-truncate font-size-15">{lease.client_id.client}</h5>
                      }
                      <div style={clientAccess[lease.client_id.client] === "read_only" ? { color: 'orange', fontWeight: 'bold' } : { color: 'green', fontWeight: 'bold' }}>{lease && lease.client_id && clientAccess[lease.client_id.client] ? clientAccess[lease.client_id.client].replace('_', " ").toUpperCase() : ''}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className='mt-1'>
                {
                  !lease.is_processing &&
                  <div className='btn-group dropstart'>
                    <ButtonDropdown
                      isOpen={dropdownOpen}
                      toggle={() => setDropdownOpen(!dropdownOpen)}
                      direction='start'
                    >
                      <DropdownToggle
                        caret
                        className="btn btn-light bg-white btn-sm"
                        style={{ border: 'none' }}
                      >
                        <i className="bx bxs-down-arrow text-lbusers" />
                      </DropdownToggle>
                      <DropdownMenu >
                        {lease.audit_category !== 'drafted' && <Link to={`/${localStorage.getItem('company')}/leases/details/${lease._id}/other`}><DropdownItem > View</DropdownItem></Link>}
                        {
                          lease && lease.client_id && clientAccess[lease.client_id.client] === 'full_access' &&
                          <>
                            {!lease.is_terminated && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { toggleVariablePayment(); setSelectedLease(lease) }}> Variable Payments</DropdownItem>}
                            {(!lease.is_terminated && lease.audit_category !== 'drafted') && <DropdownItem onClick={() => { setEditNonAccounting(true); setSelectedLease(lease) }}> Edit Non-Accounting </DropdownItem>}
                            {(!lease.is_terminated) && <DropdownItem href={lease.is_modified ? `/${localStorage.getItem('company')}/leases/edit_modify_lease?lease_id=${lease._id}&client_id=${lease.client_id._id}` : `/${localStorage.getItem('company')}/leases/edit?lease_id=${lease._id}&client_id=${lease.client_id._id}`}> Edit</DropdownItem>}
                            {!lease.is_terminated && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { setImpairment(true); setSelectedLease(lease) }}> Impairment</DropdownItem>}
                            {(lease.is_modified || lease.is_terminated) && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { setRevertOpen(true); setSelectedLease(lease) }}> Revert</DropdownItem>}
                            {!lease.is_terminated && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { setModifyOpen(true); setSelectedLease(lease) }}> Modify (GAAP)</DropdownItem>}
                            {(!lease.is_modified && !lease.is_terminated && lease.audit_category !== 'drafted') && <DropdownItem onClick={() => setAddClientLeasePopup(true)}> Clone</DropdownItem>}
                            {!lease.is_terminated && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { setTerminateOpen(true); setSelectedLease(lease) }}> Terminate</DropdownItem>}
                            <DropdownItem onClick={() => { setDeleteOpen(true); setSelectedLease(lease) }}> Delete</DropdownItem>
                            {mode != 'lessor' && !lease.is_terminated && lease.audit_category !== 'drafted' && !lease.is_short_term && lease.classification == 'operating' && shortTerm && <DropdownItem onClick={() => { setShortTermOpen(true);setSelectedLease(lease) }}>Apply ST Exemption</DropdownItem> }
                            {mode != 'lessor' && !lease.is_terminated && lease.audit_category !== 'drafted' && lease.is_short_term && <DropdownItem onClick={() => { setShortTermOpen(true);setSelectedLease(lease) }}>Un-apply ST Exemption</DropdownItem>}
                            {!lease.is_terminated && lease.audit_category !== 'drafted' && mode != 'lessor' && <DropdownItem className="text-success text-black" onClick={() =>{ setUsefulLife(true);setSelectedLease(lease) }}> Change Useful Life</DropdownItem>}
                          </>
                        }
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                }
              </div>
            </div>

            <div className="d-flex mt-3">
              <div>
                <h5 className="text-truncate font-size-14">
                  <div className="text-dark">
                    Lease name:
                  </div>
                </h5>
              </div>
              <div className="flex-grow-1 overflow-hidden"></div>
              <div>
              {
                lease.name && lease.name.length > 20 ? 
                <>
                  <div className="lease-name" style={{ width: '150px' }} id={"lease-" + lease._id}>{lease.name}</div>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipId == lease._id}
                    target={"lease-" + lease._id}
                    toggle={() => {
                      if(tooltipId == lease._id){
                          setTooltipId('');
                      }else{
                          setTooltipId(lease._id);
                      }
                  }}
                  >
                    {lease.name}
                  </Tooltip>
                </>
                : 
                  <div>{lease.name}</div>
              }
        </div>
            </div>

            <div className="d-flex">
              <div>
                <h5 className="text-truncate font-size-14">
                  <div className="text-dark">
                    Classification:
                  </div>
                </h5>
              </div>
              <div className="flex-grow-1 overflow-hidden"></div>
              <div>{lease.classification == 'operating' ? 'Operating' : 'Finance'}</div>
            </div>

            <div className="d-flex">
              <div>
                <h5 className="text-truncate font-size-14">
                  <div className="text-dark">
                    Discount rate:
                  </div>
                </h5>
              </div>
              <div className="flex-grow-1 overflow-hidden"></div>
              <div>{`${lease.discount_rate}%`}</div>
            </div>

            <div className="d-flex">
              <div>
                <h5 className="text-truncate font-size-14">
                  <div className="text-dark">
                    State:
                  </div>
                </h5>
              </div>
              <div className="flex-grow-1 overflow-hidden"></div>
              <div>{lease.state}</div>
            </div>

            <div className="d-flex">
              <div>
                <h5 className="text-truncate font-size-14">
                  <div className="text-dark">
                    Status:
                  </div>
                </h5>
              </div>
              <div className="flex-grow-1 overflow-hidden"></div>
              <div>{lease.status}</div>
            </div>

            <div className="mt-3">
              <div>
                <Progress
                  value={progress}
                  color={'warning'}
                  className="progress-sm"
                ></Progress>
              </div>
              <h5 className='font-size-14 py-2'>
                <span className="float-start">{moment.utc(lease.commencement).format('MM/DD/YYYY')}</span>
                <span className="float-end">{moment.utc(lease.terminal_end_date).format('MM/DD/YYYY')}</span>
              </h5>
            </div>
          </CardBody>
        </Card>
      </Col>
      <RevertLease deleteOpen={revertOpen} toggleDelete={toggleRevertLease} setDeleteOpen={setRevertOpen} lease={selectedLease} deleteL={revertL} loading={loading} />
      <DeleteLease deleteOpen={deleteOpen} toggleDelete={toggleDeleteLease} setDeleteOpen={setDeleteOpen} lease={selectedLease} deleteL={deleteL} loading={loading} />
      <ModifyDate modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={selectedLease} modifyL={modifyL} termiDate={termiDate} />
      <TerminateLease loading={loadingTerminate} terminateOpen={terminateOpen} toggleTerminateLease={toggleTerminateLease} setTerminateOpen={setTerminateOpen} termiDate={termiDate} setTermiDate={setTermiDate} modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={selectedLease} terminateL={terminateL} modifyL={modifyL}/> 
      {addClientLeasePopup &&
        <AddClientToLease addOpen={addClientLeasePopup} toggleAdd={AddClientLeaseToggle} setOpenPopup={setAddClientLeasePopup} type='clone' lease_id={lease._id} />
      }
      <ShortTermLease shortTermOpen={shortTermOpen} setShortTermOpen={setShortTermOpen} lease={selectedLease} refreshData={getLeaseData}/>
      <UsefulLife usefulLife={usefulLife} setUsefulLife={setUsefulLife} toggleUseful={toggleUseful} leaseId={selectedLease._id} refreshData={getLeaseData} />
      <Impairment impairment={impairment} setImpairment={setImpairment} toggleImpairment={toggleImpairment} leaseId={selectedLease._id} refreshData={getLeaseData} />
      <EditNonAccounting editOpen={editNonAccounting} toggleEdit={EditNonAccountingToggle} setOpenPopup={setEditNonAccounting} lease_id={selectedLease._id} refreshData={getLeaseData} />
      <AddVariablePayment type={'card'} hitApi={getLeaseData} lease_id={selectedLease._id} variablePaymentOpen={variablePaymentOpen} toggleVariablePayment={toggleVariablePayment} setVariablePaymentOpen={setVariablePaymentOpen} variablePayments={variablePaymentArr} setVariablePayments={setVariablePaymentArr} />
    </React.Fragment>
  )
}

CardGrid.propTypes = {
  lease: PropTypes.object,
  deleteLease: PropTypes.any,
  revertLease: PropTypes.any,
  terminateLease: PropTypes.any,
  getLeaseData: PropTypes.any,
  clientAccess: PropTypes.any,
}

export default CardGrid
