/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useContext } from "react"
import {Helmet} from "react-helmet";
import { Card, CardBody, Container,Modal, Row, Col, Spinner, Table, Input, Alert } from "reactstrap"
import Breadcrumbs from "components/Common/BreadcrumbBulkUpload";
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "context/authContext";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const BulkUploadDetails = () => {
    const { userData, mode } = useContext(AuthContext)
    const params = useParams();
    const navigate = useNavigate()
    const [succeddedLeases, setSucceddedLeases] = useState([]);
    const [auditFailLeases, setAuditFailLeases] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loadingLeases, setLoadingLeases] = useState(true);
    const [batchData, setBatchData] = useState({});
    const [loading, setLoading] = useState(false)
    const [loadingError, setLoadingError] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedBulkLease, setSelectedBulkLease] = useState({});
    const [value, setValue] = useState('1');
    const [logsData, setLogsData] = useState([]);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorsData, setErrorsData] = useState([]);
    const [downloading, setDownloading] = useState(false)
    const [deleteErrors, setDeleteErrors] = useState([])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columns = [
        {
            label: 'Sr. No.',
            name: 'sr_no',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '20px',height: '40px',paddingLeft: '15px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '20px',height: '50px',paddingLeft: '15px'}}),
                customBodyRender:(value, tableMeta, updateValue)=> (tableMeta && tableMeta.rowIndex) + 1,
            }
        },
        {
            label: 'Lease Name',
            name: 'name',
        },
        {
            label: 'Client Name',
            name: 'client_id.client',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite:(dataIndex)=> succeddedLeases && succeddedLeases[dataIndex] && succeddedLeases[dataIndex].client_id && succeddedLeases[dataIndex].client_id.client
            }
        },
        // {
        //     label: 'Audit Score',
        //     name: 'audit_score',
        //     options: {
        //         filter: false,
        //         sort: false,
        //     }
        // },
        {
            label: 'Actions',
            name: 'actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite:(dataIndex)=>{
                    let errFilter = errors.filter(v => (v.name == (succeddedLeases && succeddedLeases[dataIndex] && succeddedLeases[dataIndex].name)) && (v.client == (succeddedLeases && succeddedLeases[dataIndex] && succeddedLeases[dataIndex].client_id && succeddedLeases[dataIndex].client_id.client)))
                    return <>
                        {errFilter && errFilter.length > 0 && <button
                            type="button"
                            className="btn btn-lbusers btn-sm me-1"
                            onClick={() => {
                                setErrorOpen(true)
                                setErrorsData(errFilter)
                            }}
                            disabled={loading}
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            }
                            <i class='bx bx-error'></i> Issues noted
                        </button>}
                        <button
                            type="button"
                            className="btn btn-lbusers btn-sm"
                            onClick={() => {
                                window.open(`/${localStorage.getItem('company')}/leases/details/${succeddedLeases && succeddedLeases[dataIndex] && succeddedLeases[dataIndex]._id}/other`, '_blank')
                            }}
                            disabled={loading}
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            }
                            Details
                        </button>
                    </>
                }
            }
        },
    ]

    const failedColumns = [
        {
            label: 'Sr. No.',
            name: 'sr_no',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '20px',height: '40px',paddingLeft: '15px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '20px',height: '50px',paddingLeft: '15px'}}),
                customBodyRender:(value, tableMeta, updateValue)=> (tableMeta && tableMeta.rowIndex) + 1,
            }
        },
        {
            label: 'Lease Name',
            name: 'name',
        },
        {
            label: 'Client Name',
            name: 'client_id.client',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite:(dataIndex)=> auditFailLeases && auditFailLeases[dataIndex] && auditFailLeases[dataIndex].client_id && auditFailLeases[dataIndex].client_id.client
            }
        },
        // {
        //     label: 'Audit Score',
        //     name: 'audit_score',
        //     options: {
        //         filter: false,
        //         sort: false,
        //     }
        // },
        {
            label: 'Actions',
            name: 'actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite:(dataIndex)=>{
                    let errFilter = errors.filter(v => (v.name == (auditFailLeases && auditFailLeases[dataIndex] && auditFailLeases[dataIndex].name)) && (v.client == (auditFailLeases && auditFailLeases[dataIndex] && auditFailLeases[dataIndex].client_id && auditFailLeases[dataIndex].client_id.client)))
                    let client_id = auditFailLeases && auditFailLeases[dataIndex] && auditFailLeases[dataIndex].client_id && auditFailLeases[dataIndex].client_id._id
                    let lease_id = auditFailLeases && auditFailLeases[dataIndex] && auditFailLeases[dataIndex]._id
                    return <>
                        {errFilter && errFilter.length > 0 && <button
                            type="button"
                            className="btn btn-lbusers btn-sm me-1"
                            onClick={() => {
                                setErrorOpen(true)
                                setErrorsData(errFilter)
                            }}
                            disabled={loading}
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            }
                            <i class='bx bx-error'></i> Issues noted
                        </button>}
                        <button
                            type="button"
                            className="btn btn-lbusers btn-sm me-1"
                            onClick={() => {
                                window.open(`/${localStorage.getItem('company')}/leases/edit?lease_id=${lease_id}&client_id=${client_id}`, '_blank')
                            }}
                            disabled={loading}
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            }
                            Reprocess lease
                        </button>
                        <button
                            type="button"
                            className="btn btn-lbusers btn-sm"
                            onClick={() => {
                                window.open(`/${localStorage.getItem('company')}/leases/details/${lease_id}/other`, '_blank')
                            }}
                            disabled={loading}
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            }
                            Details
                        </button>
                    </>
                },
            }
        },
    ]

    const errorColumns = [
        {
            label: 'Status',
            name: 'is_succeed',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '20px',height: '40px',paddingLeft: '15px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '20px',height: '50px',paddingLeft: '15px'}}),
                customBodyRender:(value)=> (value ?  <i className='bx bx-check text-success font-weight-bold' style={{ fontSize: '30px' }}></i> : <i className='bx bx-x text-danger font-weight-bold' style={{ fontSize: '30px' }}></i>)
            }
        },
        {
            label: 'Narration',
            name: 'description',
            options: {
                filter: false,
                sort: false,
                customBodyRender:(value)=> {return <span dangerouslySetInnerHTML={{ __html: value }} />}
            }
        },
    ]

    useEffect(() => {
        getLogData();
        getLeaseData();
        getBatchData();
    }, [mode])

    const getLeaseData = async () => {
        setLoadingLeases(true);
        setSucceddedLeases([]);
        setAuditFailLeases([]);
        setErrors([]);
        try {
            const batchId = params.id;
            const { data } = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_bulk_leases?id=${batchId}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                setSucceddedLeases(data.succeddedLeases || [])
                setAuditFailLeases(data.failedLeases || [])
                setErrors(data.logsData || [])
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingLeases(false)
    }

    const getBatchData = async () => {
        setBatchData([]);
        try {
            const batchId = params.id;
            const { data } = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_batch_data?id=${batchId}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                setBatchData(data.batchData);
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
    }

    const handleDeleteLogs = async () => {
        setLoadingError(true);
        try {
            const batchId = params.id;
            const { data } = await axios.post(`${AppConfig.baseUrl}/bulk_lease/delete_errors`, { batch_id: batchId, mode: mode ? mode : 'lessee',ids: deleteErrors ? deleteErrors : []}, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            });
            if (!data.error) {
                getLeaseData();
                // toast.success('Deleted successfully');
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setErrorOpen(false);
        setLoadingError(false)
        // getLeaseData()
    }

    const downloadTemplate = async(url, type) => {
        setDownloading(true);
        try {
            if(url){
                const link = document.createElement('a');
                link.href = url;
                link.download = true
                link.target = "_blank"
                document.body.appendChild(link);
                link.click();
                toast.success(`${type} downloaded successfully`);
            }
            else{
                toast.error('Invoice not found');
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setDownloading(false)
    }
  
    const getLogData = async() =>{
        setLogsData([]);
        setLoading(true);
        try {
            const batchId = params.id;
            const {data} = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_logs_data?id=${batchId}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                const logs = data.logsData.filter(v => !v.leaseId)
                setLogsData(logs);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Batches</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumbs title="batch_details" breadcrumbItem={`Batch ID - ${batchData && batchData.batch_no || ''}`} type="batch_details" />
                    <Card>
                        <CardBody>
                            <Box>
                                <ThemeProvider theme={createTheme({
                                    components: {
                                        MuiTab: {
                                            styleOverrides: {
                                                root: {
                                                    fontFamily: '"Poppins",sans-serif',
                                                    fontSize: '0.8125rem',
                                                    fontWeight: 500,
                                                    color: '#495057',
                                                    '&:hover': {
                                                        color: '#005691'
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} variant="fullWidth">
                                                <Tab label="PROCESSED LEASES" value="1" />
                                                <Tab label="ATTENTION NEEDED" value="2" />
                                                <Tab label="ERROR LOGS" value="3" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <div>
                                                <Alert color="success" role="alert">
                                                    The leases below were processed successfully and are published for operations. 
                                                </Alert>
                                            </div>
                                            <ThemeProvider theme={createTheme({
                                                components: {
                                                    ...getMuiTheme(),
                                                    MUIDataTableToolbar: {
                                                        styleOverrides: {
                                                            actions: {
                                                                display: 'contents'
                                                            }
                                                        }
                                                    }
                                                }
                                            })}>
                                                <MUIDataTable
                                                    title=""
                                                    data={succeddedLeases}
                                                    columns={columns}
                                                    options={{
                                                        rowsPerPage: 10,
                                                        print: false,
                                                        download: false,
                                                        filter: false,
                                                        viewColumns: false,
                                                        selectableRows: "none",
                                                        search: false,
                                                        pagination: false,
                                                        rowsPerPageOptions: [],
                                                        responsive: 'scroll',
                                                        textLabels: {
                                                            body: {
                                                                noMatch: loadingLeases ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                                            },
                                                            viewColumns: {
                                                                title: "",
                                                            },
                                                        }
                                                    }}
                                                />
                                            </ThemeProvider>

                                        </TabPanel>
                                        <TabPanel value="2">
                                            <div>
                                                <Alert color="warning" role="alert">
                                                    The leases below were processed but not published as they may have one or more issues. It is recommended to resolve these issues and reprocess them. 
                                                </Alert>
                                            </div>
                                            <ThemeProvider theme={createTheme({
                                                components: {
                                                    ...getMuiTheme(),
                                                    MUIDataTableToolbar: {
                                                        styleOverrides: {
                                                            actions: {
                                                                display: 'contents'
                                                            }
                                                        }
                                                    }
                                                }
                                            })}>
                                                <MUIDataTable
                                                    title=""
                                                    data={auditFailLeases}
                                                    columns={failedColumns}
                                                    options={{
                                                        rowsPerPage: 10,
                                                        print: false,
                                                        download: false,
                                                        filter: false,
                                                        viewColumns: false,
                                                        selectableRows: "none",
                                                        search: false,
                                                        pagination: false,
                                                        rowsPerPageOptions: [],
                                                        responsive: 'scroll',
                                                        textLabels: {
                                                            body: {
                                                                noMatch: loadingLeases ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                                            },
                                                            viewColumns: {
                                                                title: "",
                                                            },
                                                        }
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <div>
                                                <Alert color="danger" role="alert">
                                                Below is the error log that explains why certain rows were not processed. You can use the download button to get a new excel sheet that highlights these issues. You can update the same sheet and re-upload. 
                                                </Alert>
                                            </div>
                                            <ThemeProvider theme={createTheme({
                                                components: {
                                                    ...getMuiTheme(),
                                                    MUIDataTableToolbar: {
                                                        styleOverrides: {
                                                            actions: {
                                                                display: 'contents'
                                                            }
                                                        }
                                                    }
                                                }
                                            })}>
                                                <MUIDataTable
                                                    title={
                                                        logsData && logsData.length > 0 && batchData && batchData.download_url &&
                                                        <Row className="row">
                                                            <Col>
                                                                <div
                                                                    style={{ float: "right" }}
                                                                >
                                                                    
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-lbusers btn-sm"
                                                                            onClick={() => downloadTemplate(batchData && batchData.download_url, "Template")}
                                                                            disabled={downloading}
                                                                        >
                                                                            {
                                                                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                            }
                                                                            Download
                                                                        </button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    data={logsData}
                                                    columns={errorColumns}
                                                    options={{
                                                        rowsPerPage: 10,
                                                        print: false,
                                                        download: false,
                                                        filter: false,
                                                        viewColumns: false,
                                                        selectableRows: "none",
                                                        search: false,
                                                        pagination: false,
                                                        rowsPerPageOptions: [],
                                                        responsive: 'scroll',
                                                        textLabels: {
                                                            body: {
                                                                noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                                            },
                                                            viewColumns: {
                                                                title: "",
                                                            },
                                                        }
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </TabPanel>
                                    </TabContext>
                                </ThemeProvider>
                            </Box>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
            size="lg"
            isOpen={errorOpen}
            toggle={() => {
                setErrorOpen(false);
                setErrorsData([])
                setDeleteErrors([])
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Error logs</h5>
                <button
                    type="button"
                    onClick={() => {
                        setErrorOpen(false);
                        setErrorsData([])
                        setDeleteErrors([])
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <div className="modal-body">
                    <div className="table-responsive">
                        <Table className="table mb-0 text-center customTable">
                            <thead className="table-light tableHead">
                                <tr style={{ paddingRight: '15px' }}>
                                    <th style={{ width: '50px' }}>
                                        {/* <div className="mt-4">
                                            <Input
                                                className="form-check-input"
                                                name="selectAll"
                                                type="checkbox"
                                                checked={checkAll()}
                                                onClick={(e) => {
                                                    if (e.target.checked) {
                                                        const newArr = tableData.map(v => {
                                                            v.is_processed = true
                                                            return v;
                                                        })
                                                        const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                        setVariablePayments(all)
                                                    } else {
                                                        const newArr = tableData.map(v => {
                                                            v.is_processed = false
                                                            return v;
                                                        })
                                                        const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                        setVariablePayments(all)
                                                    }
                                                }}
                                            />
                                            <label
                                                className="form-check-label ms-2"
                                                htmlFor="select"
                                            >
                                                Select
                                            </label>
                                        </div> */}
                                    </th>
                                    <th style={{textAlign:'left'}}>Errors</th>
                                    <th style={{ width: '150px', textAlign:'left' }}>Category</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {errorsData && errorsData.length > 0 && errorsData.map((data, i) => {
                                    return <tr key={i} style={{ textDecoration: deleteErrors.includes(data._id) ? 'line-through' : 'none', textDecorationColor: deleteErrors.includes(data._id) ? 'red' : '' }} >
                                        <td className="text-center" style={{ width: '50px' }}>
                                            <div className="mt-2">
                                                <Input
                                                    className="form-check-input"
                                                    name="includePayment"
                                                    id={i}
                                                    type="checkbox"
                                                    checked={deleteErrors.includes(data._id)}
                                                    onClick={() => {
                                                        let newArr = [...deleteErrors];
                                                        if(deleteErrors.includes(data._id)){
                                                            newArr = newArr.filter(v => v !== data._id)
                                                            setDeleteErrors(newArr)
                                                        }else{
                                                            newArr.push(data._id)
                                                            setDeleteErrors(newArr)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td><div className="mt-2" style={{textAlign:'left'}}><span dangerouslySetInnerHTML={{ __html: data.description }} /></div></td>
                                        <td style={{ width: '150px', textAlign:'left' }}><div className="mt-2">{data.category ? data.category : ''}</div></td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="modal-footer mt-3 me-3">
                    <button
                        type="button"
                        className="btn btn-lbusers"
                        onClick={() => {
                            setErrorOpen(false);
                            setErrorsData([])
                            setDeleteErrors([])
                        }}
                        disabled={loadingError}
                    >
                        {
                            loadingError && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        }
                        Cancel
                    </button>
                    <button type="button" className="btn btn-lbusers" disabled={loadingError || (deleteErrors && deleteErrors.length < 1)} onClick={handleDeleteLogs}>
                        {
                            loadingError && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        }
                        Resolve
                    </button>
                </div>
        </Modal>
        </Layout>
    )
}

export default BulkUploadDetails