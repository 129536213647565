import React, { useState, useEffect, useContext } from "react"
import {Helmet} from "react-helmet";

import {
    Card,
    CardBody,
    Col,
    Container,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Modal,
    Form, Spinner, Table, Nav
} from "reactstrap"
import classnames from "classnames"
import { useLocation, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumbclientdetails"
import AddLeaseStepOne from "./addLeaseStepOne";
import AddLeaseStepTwo from "./addLeaseStepTwo";
import AddLeaseStepThree from "./addLeaseStepThree";
import AddLeaseStepFour from "./addLeaseStepFour";
import AddLeaseStepFive from "./addLeaseStepFive";
import AddLeaseStepSix from "./addLeaseStepSix";
import { object, string, number, date, array, boolean } from "yup";
import { useFormik } from "formik";
import moment from 'moment';
import { newPost, get } from 'helpers/axios_helper';
import AppConfig from 'constants/config';
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext"
import UploadFiles from "./uploadFiles"
import { commaSeperated } from "helpers/formatters"
import Layout from "components/VerticalLayout";

const ModifyLease = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setactiveTab] = useState(1)
    const [activeTab1, setActiveTab1] = useState("2");
    const [addFile, setAddFile] = useState(false)
    const [passedSteps, setPassedSteps] = useState([1])
    const [clientName, setClientName] = useState('')
    const { userData, reloadProcessingStatus, mode } = useContext(AuthContext);
    const [leaseDraftData, setLeaseDraftData] = useState('')
    const [leaseDetails, setLeaseDetails] = useState('')
    const [loadingData, setLoadingData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])
    const [nextPreviewOpen, setNextPreviewOpen] = useState(false);
    const [sortedPayments, setSortedPayments] = useState([]);
    const [totalVal, setTotalVal] = useState(0);
    const [totalVal1, setTotalVal1] = useState(0);
    const [totalVal2, setTotalVal2] = useState(0);
    const [termEndPayments, setTermEndPayments] = useState([]);
    const [refundableDeposit, setRefundableDeposit] = useState([]);
    const [clientData, setClientData] = useState({});
    const [totalVal3, setTotalVal3] = useState(0);
    const [variablePayments, setVariablePayments] = useState([]);
    const [shortTermOpen, setShortTermOpen] = useState(false);

    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    let path = location && location.pathname.split('/');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 6) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }
    const toggleModel = () => {
        setAddFile(!addFile)
    }
    const toggleNextPreview = () => {
        setActiveTab1('2')
        setNextPreviewOpen(!nextPreviewOpen);
    }
    const step1Schema = object().shape({
        leaseName: string().required("Please enter Lease name"),
        state: string().required("Please select your state"),
        type: string().required("Please select your type"),
        zipcode: string().length(5, 'Please enter valid zipcode')
    });
    const step2Schema = object().shape({
        commencementDate: date().required("Please enter commencement date"),
        howLong: number().min(0, 'Initial term must be greater than or equal to 0').required("Please enter numeric value"),
        leaseEndDate: date().required("Please enter lease end date"),
        extendForTheLessee: array().of(object().shape({
            howLong: number().min(0),
            notify: number().min(0),
        })),
        extendForTheLessor: array().of(object().shape({
            howLong: number().min(0),
            notify: number().min(0),
        }))
    });
    const step3Schema = object().shape({
        discountRate: number().min(0, 'Must be greater than 0').max(100, 'Must be less than 100').required("Please enter discount rate"),

        periodicLeasePayments: array().of(object().shape({
            amount: number().min(0, 'Must be greater than 0'),
            annualFixedAmountPer: number().min(0, 'Must be greater than 0').max(100, 'Must be less than 100'),
            annualFixedAmount: number().min(0, 'Must be greater than 0'),
        })),

        refundableDepositAmount: number().min(0, 'Must be greater than 0'),
        refundableDepositPaymentDate: date().when('refundableDepositAmount', {
            is: (val) => val > 0,
            then: (schema) => schema.required("Payment date is required"),
            otherwise: (schema) => schema,
        }),

        paymentsBeforeCommencement: array().of(object().shape({
            amount: number().min(0, 'Must be greater than 0'),
        })),

        probableOwedUnderRVGTotalAmount: number().min(0, 'Must be greater than 0'),
        probableOwedUnderRVGAmount: number().min(0, 'Must be greater than 0'),

        incentives: array().of(object().shape({
            amount: number().max(0, 'Must be a negative number'),
        })),

        paymentsForPurchaseReason: boolean(),
        paymentsForPurchaseAmount: number().when('paymentsForPurchaseReason', {
            is: true,
            then: (schema) => schema.min(0, 'Must be greater than 0').required("Amount is required"),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0'),
        }),

        penaltyForTerminatingReason: boolean(),
        penaltyForTerminatingAmount: number().when('penaltyForTerminatingReason', {
            is: true,
            then: (schema) => schema.min(0, 'Must be greater than 0').required("Amount is required"),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0'),
        }),

        initialDirectCostAmount: number().min(0, 'Must be greater than 0'),
    });
    const step4Schema = object().shape({
        transitionDate: date(),
        prepaidRent: number().positive().min(0),
        unamortizedInitialDirectCost: number().positive().min(0),
        leaseIncentives: number().max(-1),
        topic420Liability: number().max(-1),
        otherAccounts: number(),
    });
    const step5Schema = object().shape({
        transferOwnership: boolean(),
        certainToExercise: boolean(),
        usefulEconomicLife: number().when(['transferOwnership', 'certainToExercise', 'classify'], {
            is: (transferOwnership, certainToExercise, classify) => ((certainToExercise === true || transferOwnership === true) && (classify == 'financing' || classify == 'determine')),
            then: (schema) => schema.min(1, 'Must be greater than 0').required(`This field is required`),
            otherwise: (schema) => schema.min(1, 'Must be greater than 0')
        }, [['transferOwnership', 'certainToExercise', 'classify']]),
        amountForOptionToPurchase: number().when('certainToExercise', {
            is: true,
            then: (schema) => schema.min(0, 'Must be greater than 0').required(`This field is required`),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0')
        }),
        fairValue: number().when(['classify', 'specializedNature', 'transferOwnership', 'certainToExercise', 'economicLifeOfUnderlyingAsset'], {
            is: (classify, transferOwnership, certainToExercise, specializedNature, economicLifeOfUnderlyingAsset) => (classify == 'determine' && specializedNature === false && certainToExercise === false && transferOwnership === false && economicLifeOfUnderlyingAsset === false),
            then: (schema) => schema.min(0, 'Must be greater than 0').required(`This field is required`),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0')
        }, [['classify', 'specializedNature', 'transferOwnership', 'certainToExercise', 'economicLifeOfUnderlyingAsset']])
    });

    const step6Schema = object().shape({
        leasingComponentAmount: number().positive().min(0),
        nonLeasingComponent: array().of(object().shape({
            amount: number().min(0, 'Must be greater than 0'),
        })),
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            // step 1
            leaseName: leaseDraftData ? (path[3] == 'modify') ? leaseDraftData.leaseName + ' (mod)' : path[3] == 'edit' || path[3] == 'add_bulk_lease' ? leaseDraftData.leaseName : '' : '',
            type: leaseDraftData ? leaseDraftData.type : 'Office',
            assetDisc: leaseDraftData ? leaseDraftData.assetDisc : '',
            assetTypeValue: leaseDraftData ? leaseDraftData.assetTypeValue : '',
            assetId: leaseDraftData ? leaseDraftData.assetId : '',
            address1: leaseDraftData ? leaseDraftData.address1 : '',
            address2: leaseDraftData ? leaseDraftData.address2 : '',
            city: leaseDraftData ? leaseDraftData.city : '',
            state: leaseDraftData ? leaseDraftData.state : '',
            zipcode: leaseDraftData ? leaseDraftData.zipcode : '',
            reporting_standard: leaseDraftData && leaseDraftData.reporting_standard ? leaseDraftData.reporting_standard : 'FASB ASC 842',
            lessee_or_lessor_name: leaseDraftData ? leaseDraftData.lessee_or_lessor_name : '',
            lessee_or_lessor_email: leaseDraftData ? leaseDraftData.lessee_or_lessor_email : '',
            lessee_or_lessor_address: leaseDraftData ? leaseDraftData.lessee_or_lessor_address : '',

            //   step 2
            commencementDate: sp.get('m_date') ? sp.get('m_date') : '',
            leaseEndDate: leaseDraftData && leaseDraftData.leaseEndDate ? leaseDraftData.leaseEndDate : '',
            typeStep2: 'days',
            howLong: sp.get('m_date') && leaseDraftData && leaseDraftData.leaseEndDate ? moment(leaseDraftData.leaseEndDate).diff(moment(sp.get('m_date'), 'MM/DD/YYYY'), 'days') : 0,
            initialTerm: leaseDraftData ? leaseDraftData.initialTerm : '',
            totalLeaseTerm: leaseDraftData ? leaseDraftData.totalLeaseTerm : '',
            terminalLeaseEndDate: leaseDraftData && leaseDraftData.terminalLeaseEndDate ? leaseDraftData.terminalLeaseEndDate : sp.get('m_date') ? sp.get('m_date') : '',
            lastExtensionDate: leaseDraftData && leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate
                : leaseDraftData && leaseDraftData.extendForTheLessee && leaseDraftData.extendForTheLessee.length > 0 && leaseDraftData.extendForTheLessee[leaseDraftData.extendForTheLessee.length - 1].extendTheLease ? leaseDraftData.extendForTheLessee[leaseDraftData.extendForTheLessee.length - 1].endDate
                    : leaseDraftData && leaseDraftData.terminalLeaseEndDate ? leaseDraftData.terminalLeaseEndDate
                        : moment(new Date()).format('MM/DD/YYYY'),
            extendForTheLessee: leaseDraftData && leaseDraftData.extendForTheLessee && leaseDraftData.extendForTheLessee.length > 0 ? leaseDraftData.extendForTheLessee : [{
                extendTheLease: false,
                certainToExercise: false,
                startDate: leaseDraftData && leaseDraftData.leaseEndDate ? moment(leaseDraftData.leaseEndDate).add(1, 'day').format('MM/DD/YYYY') : sp.get('m_date') ? moment(sp.get('m_date')).add(1, 'days').format('MM/DD/YYYY') : moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                howLong: 0,
                type: 'months',
                endDate: leaseDraftData && leaseDraftData.leaseEndDate ? moment(leaseDraftData.leaseEndDate).add(1, 'day').format('MM/DD/YYYY') : sp.get('m_date') ? moment(sp.get('m_date')).add(1, 'day').format('MM/DD/YYYY') : moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                notify: 0,
                notifyType: 'months',
                notifyDate: leaseDraftData && leaseDraftData.leaseEndDate ? leaseDraftData.leaseEndDate : sp.get('m_date') ? sp.get('m_date') : moment(new Date()).format('MM/DD/YYYY')
            }],
            extendForTheLessor: [{
                extendTheLease: false,
                certainToExercise: false,
                startDate: leaseDraftData && leaseDraftData.leaseEndDate ? moment(leaseDraftData.leaseEndDate).add(1, 'day').format('MM/DD/YYYY') : sp.get('m_date') ? moment(sp.get('m_date')).add(1, 'day').format('MM/DD/YYYY') : moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                type: 'months',
                howLong: 0,
                endDate: leaseDraftData && leaseDraftData.leaseEndDate ? moment(leaseDraftData.leaseEndDate).add(1, 'day').format('MM/DD/YYYY') : sp.get('m_date') ? moment(sp.get('m_date')).add(1, 'day').format('MM/DD/YYYY') : moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                notifyDate: leaseDraftData && leaseDraftData.leaseEndDate ? leaseDraftData.leaseEndDate : sp.get('m_date') ? sp.get('m_date') : moment().format('MM/DD/YYYY'),
                notify: 0,
                notifyType: 'months'
            }],
            is_short_term:false,

            //   step 3
            discountRate: leaseDraftData ? leaseDraftData.discountRate : '',
            interest_rate_method: leaseDraftData && leaseDraftData.interest_rate_method ? leaseDraftData.interest_rate_method : clientData ? clientData.interest_rate_method : 'true_annual',
            calculation_type: leaseDraftData ? leaseDraftData.calculation_type : 'monthly',
            currency: leaseDraftData ? leaseDraftData.currency : 'USD',
            paymentPreview: '',
            oldPaymentPreview: '',
            extensionPreview: '',
            oldExtensionPreview: '',
            variablePreview: leaseDraftData && leaseDraftData.variablePreview ? leaseDraftData.variablePreview : '',
            oldVariablePreview: leaseDraftData && leaseDraftData.oldVariablePreview ? leaseDraftData.oldVariablePreview : '',
            periodicLeasePayments: [{
                amount: 0,
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: 0,
                annualFixedAmount: 0,
                description: ''
            }],

            refundableDepositAmount: 0,
            refundableDepositPaymentDate: '',
            refundableDepositNotificationDate: '',
            refundableDepositReceiptDate: '',
            refundableDepositReceiptAmount: '',
            refundableDepositSummary: '',

            paymentsBeforeCommencement: [{
                amount: 0,
                paymentDate: '',
                description: ''
            }],

            probableOwedUnderRVGTotalAmount: 0,
            probableOwedUnderRVGPaymentDate: '',
            probableOwedUnderRVGAmount: 0,
            probableOwedUnderRVGTotalAmountDesc: '',

            incentives: [{
                amount: 0,
                receiptDate: '',
                description: ''
            }],

            paymentsForPurchaseReason: false,
            paymentsForPurchaseAmount: '',
            paymentsForPurchasePaymentDate: '',
            paymentsForPurchaseDescription: '',

            penaltyForTerminatingReason: false,
            penaltyForTerminatingAmount: '',
            penaltyForTerminatingPaymentDate: '',
            penaltyForTerminatingDescription: '',

            initialDirectCostAmount: '',
            initialDirectCostAmountPaymentDate: '',
            initialDirectCostDescription: '',

            variableLeasePayments: leaseDraftData ? leaseDraftData.variableLeasePayments : [{
                category: 'new_category',
                new_category: '',
                amount: 0,
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: 0,
                annualFixedAmount: 0
            }],

            //   step 4
            transitionDate: '',
            acquisitionRelated: 0,
            prepaidRent: 0,
            unamortizedInitialDirectCost: 0,
            leaseIncentives: 0,
            deferredRent: 0,
            topic420Liability: 0,
            otherAccounts: 0,
            description: '',

            // step 5
            classify: leaseDraftData ? leaseDraftData.classify : 'operating',
            typeStep5: leaseDraftData ? leaseDraftData.typeStep5 : 'months',
            transferOwnership: leaseDraftData ? leaseDraftData.transferOwnership : false,
            certainToExercise: leaseDraftData ? leaseDraftData.certainToExercise : false,
            amountForOptionToPurchase: 0,
            usefulEconomicLife: leaseDraftData ? leaseDraftData.usefulEconomicLife : '',
            fairValue: leaseDraftData ? leaseDraftData.fairValue : '',
            specializedNature: leaseDraftData ? leaseDraftData.specializedNature : false,
            economicLifeOfUnderlyingAsset: leaseDraftData && leaseDraftData.economicLifeOfUnderlyingAsset === true ? true : false,
            substantiallyAllOfTheFairValue: leaseDraftData ? leaseDraftData.substantiallyAllOfTheFairValue : false,

            // step 6
            leasingComponentLabel: '',
            leasingComponentAmount: 0,
            leasingPer: 100,
            nonLeasingPer: '0',
            nonLeasingComponent: [{
                label: '',
                amount: 0
            }]

        },
        validationSchema: activeTab === 1 ? step1Schema : activeTab === 2 ? step2Schema : activeTab === 3 ? step3Schema :
            activeTab === 4 ? step5Schema : activeTab === 5 ? step6Schema : '',
        onSubmit: async (values) => {
            values.uploaded_files = [...selectedFiles]
                values.mode = mode ? mode : 'lessee'
            if (activeTab == 3) {
                if (!values.oldPaymentPreview || (values.oldPaymentPreview && values.oldPaymentPreview.length <= 0)) {
                    toast.error(`Please make sure you have added your payments and saved them.`);
                } else {
                    try {
                        setActiveTab1('2')
                        let data = values.oldPaymentPreview.sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let extendData = values.oldExtensionPreview.sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let variableData = values.oldVariablePreview.sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let allPayments = [...data, ...extendData, ...variableData].sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let wrongDate = false
                        for (const i of data) {
                            if (i.type == 'Periodic lease payments' && (moment(i.date).isBefore(moment(validation.values.commencementDate)) || moment(i.date).isAfter(moment(validation.values.terminalLeaseEndDate)))) {
                                wrongDate = true
                            }
                        }
                        let wrongDate1 = false
                        for (const i of allPayments) {
                            if (i.type == 'Periodic lease payments' && (moment(i.date).isBefore(moment(validation.values.commencementDate)) || moment(i.date).isAfter(moment(validation.values.lastExtensionDate)))) {
                                wrongDate1 = true
                            } else if (i.type == 'Payments before commencement' && moment(i.date).isSameOrAfter(moment(validation.values.commencementDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'Initial direct cost' && moment(i.date).isAfter(moment(validation.values.lastExtensionDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'Penalty for terminating' && moment(i.date).isBefore(moment(validation.values.commencementDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'Payments for purchase option' && moment(i.date).isBefore(moment(validation.values.commencementDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'variable lease payments' && (moment(i.date).isBefore(moment(validation.values.commencementDate)) || moment(i.date).isAfter(moment(validation.values.lastExtensionDate)))) {
                                wrongDate1 = true
                            }
                        }
                        let wrongDate2 = false
                        for (const i of extendData) {
                            if (i.type == 'Periodic lease payments' && (moment(i.date).isBefore(moment(validation.values.terminalLeaseEndDate)))) {
                                wrongDate2 = true
                            }
                        }
                        if (wrongDate1) {
                            toast.error(`Please select valid date.`);
                        } else if (wrongDate || wrongDate2) {
                            toast.error(`Please save periodic lease payments again.`);
                        } else {
                            let val = values.oldPaymentPreview.reduce((acc, current) => acc + Number(current.amount), 0)
                            let val1 = extendData.reduce((acc, current) => acc + Number(current.amount), 0)
                            let refund = allPayments.filter(e => e.type == 'Refundable Deposit')
                            let val2 = refund.reduce((acc, current) => acc + Number(current.amount), 0)
                            let val3 = values.oldVariablePreview.reduce((acc, current) => acc + Number(current.amount), 0)
                            setTermEndPayments(extendData)
                            setTotalVal(val - val2);
                            setTotalVal1(val1);
                            setRefundableDeposit(refund)
                            setTotalVal2(val2);
                            setSortedPayments(data);
                            setNextPreviewOpen(true);
                            setTotalVal3(val3);
                            setVariablePayments(variableData)
                        }
                    } catch (error) {
                        console.log(error)
                    }

                }
            } else if (activeTab == 5) {
                if (values.transitionDate == '') {
                    values.acquisitionRelated = 0;
                    values.prepaidRent = 0;
                    values.unamortizedInitialDirectCost = 0;
                    values.leaseIncentives = 0;
                    values.deferredRent = 0;
                    values.topic420Liability = 0;
                    values.otherAccounts = 0;
                }
                hitAxios(values);
            } else {
                if (activeTab == 1) {
                    setLoading(true)
                    let value = await get(`${AppConfig.baseUrl}/lease/unique_name?name=${validation.values.leaseName}&mode=${mode ? mode : 'lessee'}&client_id=${leaseDraftData.clientId}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                        }
                    })
                    if (value.error) {
                        if (path[3] == 'edit' && leaseDraftData.leaseName == values.leaseName) {
                            toggleTab(activeTab + 1)
                        } else {
                            validation.setFieldError('leaseName', 'Lease name already exists');
                        }
                    } else {
                        toggleTab(activeTab + 1)
                    }
                    setLoading(false)
                } else if (activeTab == 2) {
                    if (validation.values.lastExtensionDate && validation.values.commencementDate) {
                        validation.setFieldValue(`refundableDepositPaymentDate`, validation.values.refundableDepositPaymentDate ? validation.values.refundableDepositPaymentDate : (moment(validation.values.commencementDate).subtract(1, 'days').format('MM/DD/YYYY')))
                        validation.setFieldValue(`paymentsBeforeCommencement[0].paymentDate`, validation.values.paymentsBeforeCommencement[0] && validation.values.paymentsBeforeCommencement[0].paymentDate ? validation.values.paymentsBeforeCommencement[0].paymentDate : (moment(validation.values.commencementDate).subtract(1, 'days').format('MM/DD/YYYY')))
                        validation.setFieldValue(`probableOwedUnderRVGPaymentDate`, validation.values.probableOwedUnderRVGPaymentDate ? validation.values.probableOwedUnderRVGPaymentDate : (moment(validation.values.lastExtensionDate).format('MM/DD/YYYY')))
                        validation.setFieldValue(`paymentsForPurchasePaymentDate`, validation.values.paymentsForPurchasePaymentDate ? validation.values.paymentsForPurchasePaymentDate : (moment(validation.values.lastExtensionDate).format('MM/DD/YYYY')))
                        validation.setFieldValue(`penaltyForTerminatingPaymentDate`, validation.values.penaltyForTerminatingPaymentDate ? validation.values.penaltyForTerminatingPaymentDate : (moment(validation.values.lastExtensionDate).format('MM/DD/YYYY')))
                        validation.setFieldValue(`initialDirectCostAmountPaymentDate`, validation.values.initialDirectCostAmountPaymentDate ? validation.values.initialDirectCostAmountPaymentDate : (moment(validation.values.commencementDate).subtract(1, 'days').format('MM/DD/YYYY')))
                        validation.setFieldValue(`periodicLeasePayments[0].paymentDate`, validation.values.periodicLeasePayments[0] && validation.values.periodicLeasePayments[0].paymentDate ? validation.values.periodicLeasePayments[0].paymentDate : moment(validation.values.commencementDate).format('MM/DD/YYYY'))
                        validation.setFieldValue(`periodicLeasePayments[0].paymentEndDate`, validation.values.periodicLeasePayments[0] && validation.values.periodicLeasePayments[0].paymentEndDate ? validation.values.periodicLeasePayments[0].paymentEndDate : moment(validation.values.commencementDate).subtract(1, 'day').add(12, 'months').format('MM/DD/YYYY'))
                        validation.setFieldValue(`incentives[0].receiptDate`, validation.values.incentives[0] && validation.values.incentives[0].receiptDate ? validation.values.incentives[0].receiptDate : moment(validation.values.commencementDate).subtract(1, 'day').format('MM/DD/YYYY'))
                    }                                  
                    if((moment(moment(validation.values.terminalLeaseEndDate).format('YYYY-MM-DD')).diff(moment(moment(leaseDraftData.terminalLeaseEndDate).add(1,'day').format('YYYY-MM-DD')),'months')) < 12 && leaseDraftData.is_short_term && mode != 'lessor'){
                        setShortTermOpen(true)
                    }else{
                        validation.setFieldValue(`is_short_term`,false)
                        toggleTab(activeTab + 1) 
                    }
                } else {
                    toggleTab(activeTab + 1)
                }
            }
        }
    });
    const hitAxios = async (data) => {
        setLoading(true)
        let newData = { ...data, lease_id: sp.get('lease_id'), clientId: leaseDraftData.clientId, mod_type: sp.get('mod_type'), percentage: sp.get('percentage') }

        let value = await newPost(`${AppConfig.baseUrl}/lease_draft/modifyLease`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (value.error === false) {
            reloadProcessingStatus()
            toast.success('Lease saved successfully');
            let avti = 'modify';
            let data = { activity: avti, lease_id: value.data._id, client_id: leaseDraftData.clientId, client_name: clientName, mode: mode ? mode : 'lessee' }
            let value1 = await newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            navigate(`/${localStorage.getItem('company')}/leases/details/${value1.data.lease_id}/refresh`)
        } else {
            toast.error(value.title || 'Lease not saved');
        }
        setLoading(false)
    }
    let sty = activeTab === 1 ? 'space-between' : 'end';

    const getClientName = async() => {
        let value = await get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&access_type=full_access`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        let id = leaseDraftData.clientId;
        let clientData = value && value.clientData.filter((a) => a.client_id._id == id)
        setClientData(clientData[0].client_id)
        setClientName(clientData[0].client_id.client)
        if (clientData.length > 0 && path[3] == 'add') {
            let client = clientData[0].client_id;
            if (new Date(client.transition_date).getTime() > new Date(validation.values.commencementDate).getTime()) {
                validation.setFieldValue(`transitionDate`, (moment.utc(client.transition_date).format('MM/DD/YYYY')))
            } else {
                validation.setFieldValue(`transitionDate`, (''))
            }
        }
    }

    useEffect(() => { // get client transition date
        if (userData && userData.id && path[3] === 'modify' && leaseDraftData && leaseDraftData.clientId) {
            getClientName()
        }
    }, [leaseDraftData, userData])

    const getLeaseDraftData = async() => {
        setLoadingData(true)
        try {
            let { data } = await get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${sp.get('lease_id')}&mode=${mode ? mode : 'lessee'}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            let value = await get(`${AppConfig.baseUrl}/lease/lease_details?Id=${sp.get('lease_id')}&mode=${mode ? mode : 'lessee'}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (data && data.lease) {
                setLeaseDraftData(data.lease)
            }
            if (!value.error) {
                setLeaseDetails(value.data)
            }
        } catch (err) {

        }
        setLoadingData(false)
    }

    useEffect(() => {
        if (path[3] == 'modify' && sp.get('lease_id')) {
            getLeaseDraftData()
        }
    }, [])

    useEffect(() => {
        if (leaseDraftData && Object.keys(leaseDraftData).length > 0) {
            leaseDraftData.extendForTheLessee.map((d, i) => {
                if (d) {
                    let elem = document.getElementById(`extendForTheLessee[${i}].certainToExercise`);
                    if (elem) {
                        elem.checked = d.certainToExercise;
                    }
                }
            })
            let elem2 = document.getElementById(`paymentsForPurchaseReason`);
            elem2 && setTimeout(() => elem2.checked = leaseDraftData.paymentsForPurchaseReason, 500)
            let elem5 = document.getElementById('transferOwnership');
            elem5 && setTimeout(() => elem5.checked = leaseDraftData.transferOwnership)
            let elem6 = document.getElementById('specializedNature');
            elem6 && setTimeout(() => elem6.checked = leaseDraftData.specializedNature)
            let elem7 = document.getElementById('substantiallyAllOfTheFairValue');
            elem7 && setTimeout(() => elem7.checked = leaseDraftData.substantiallyAllOfTheFairValue)
            let elem8 = document.getElementById('periodicLeasePayments[0].frequency');
            elem8 && setTimeout(() => elem8.value = leaseDraftData.periodicLeasePayments[0].frequency)
        }
    }, [leaseDraftData])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeTab])

    useEffect(() => {
        if ((leaseDraftData && Object.keys(leaseDraftData).length > 0) || (clientData && Object.keys(clientData).length > 0)) {
            validation.setFieldValue('reporting_standard', leaseDraftData && leaseDraftData.reporting_standard ? leaseDraftData.reporting_standard : clientData && clientData.reporting_standard ? clientData.reporting_standard : "FASB ASC 842")
            validation.setFieldValue('interest_rate_method', path[3] == 'add' && clientData && clientData.interest_rate_method ? clientData.interest_rate_method : leaseDraftData && leaseDraftData.interest_rate_method ? leaseDraftData.interest_rate_method : "true_annual")
            validation.setFieldValue('classify', leaseDraftData && (leaseDraftData.reporting_standard == "GASB 87" || leaseDraftData.reporting_standard == "IFRS 16") ? "financing" : leaseDraftData && leaseDraftData.paymentsForPurchaseReason && leaseDraftData.classify !== 'financing' ? 'determine' : leaseDraftData && leaseDraftData.classify ? leaseDraftData.classify : 'operating')
        }
    }, [leaseDraftData, clientData])

    useEffect(() => {
        if (leaseDraftData && moment(sp.get('m_date'), 'MM/DD/YYYY').isAfter(moment(leaseDraftData.leaseEndDate, 'MM/DD/YYYY'))) {
            let data = leaseDraftData && leaseDraftData.extendForTheLessee ? leaseDraftData.extendForTheLessee.filter(e => moment(sp.get('m_date'), 'MM/DD/YYYY').isSameOrAfter(moment(e.startDate, 'MM/DD/YYYY')) && moment(sp.get('m_date'), 'MM/DD/YYYY').isSameOrBefore(moment(e.endDate, 'MM/DD/YYYY'))) : []
            let extendData = data && data[0] && leaseDraftData && leaseDraftData.extendForTheLessee ? leaseDraftData.extendForTheLessee.filter(e => moment(e.startDate, 'MM/DD/YYYY').isAfter(moment(data[0].endDate), 'MM/DD/YYYY')) : []
            validation.setFieldValue('leaseEndDate', data && data[0] ? data[0].endDate : leaseDraftData.leaseEndDate)
            validation.setFieldValue('howLong', data && data[0] ? moment(data[0].endDate, 'MM/DD/YYYY').diff(moment(sp.get('m_date'), 'MM/DD/YYYY'), 'days') : moment(leaseDraftData.leaseEndDate, 'MM/DD/YYYY').diff(moment(sp.get('m_date'), 'MM/DD/YYYY'), 'days'))
            if (extendData && extendData[0]) {
                extendData[0].extendTheLease = extendData && extendData.length > 0 ? true : false
            }
            validation.setFieldValue('extendForTheLessee', extendData && extendData.length > 0 ? extendData : [{
                extendTheLease: false,
                certainToExercise: false,
                startDate: data && data[0] ? moment(data[0].endDate, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY') : moment(leaseDraftData.leaseEndDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY'),
                howLong: 0,
                type: 'months',
                endDate: data && data[0] ? moment(data[0].endDate, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY') : moment(leaseDraftData.leaseEndDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY'),
                notify: 0,
                notifyType: 'months',
                notifyDate: data && data[0] ? data[0].endDate : leaseDraftData.leaseEndDate
            }])
            validation.setFieldValue('extendForTheLessor', [{
                extendTheLease: false,
                certainToExercise: false,
                startDate: data && data[0] ? moment(data[0].endDate, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY') : moment(leaseDraftData.leaseEndDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY'),
                type: 'months',
                howLong: 0,
                endDate: data && data[0] ? moment(data[0].endDate, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY') : moment(leaseDraftData.leaseEndDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY'),
                notifyDate: data && data[0] ? data[0].endDate : leaseDraftData.leaseEndDate,
                notify: 0,
                notifyType: 'months'
            }])
        } else {
            validation.setFieldValue('leaseEndDate', leaseDraftData && leaseDraftData.leaseEndDate ? leaseDraftData.leaseEndDate : '')
            validation.setFieldValue('howLong', sp.get('m_date') && leaseDraftData && leaseDraftData.leaseEndDate ? moment(leaseDraftData.leaseEndDate).diff(moment(sp.get('m_date'), 'MM/DD/YYYY'), 'days') : 0)
        }
    }, [leaseDraftData, sp.get('m_date')])

    useEffect(() => {
        if (mode && clientData && clientData.client_type && mode != clientData.client_type) {
            window.location.href = `/${localStorage.getItem('company')}/leases`
        }
    }, [mode, clientData])

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Modify Lease</title>
                </Helmet>
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" name={clientName} breadcrumbItem={`${path[3] == 'modify' ? 'Modify' : 'Add New'} Lease`}
                        dates={`${moment(new Date(validation.values.commencementDate)).format('MM/DD/YYYY')} to
                     ${Number(validation.values.howLong) >= 0 ? moment(new Date(validation.values.terminalLeaseEndDate)).format('MM/DD/YYYY') : moment(new Date(validation.values.commencementDate)).format('MM/DD/YYYY')}`} />
                    {
                        loadingData ?
                            <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                            :
                            <Row>
                                <Col lg="12" sm="12">
                                    <Card>
                                        <CardBody style={{ padding: 0 }}>
                                            <div className="wizard clearfix">
                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 1 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                                // onClick={() => {
                                                                //     setactiveTab(1)
                                                                // }}
                                                                disabled={!(passedSteps || []).includes(1)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">1</div>
                                                                    <div className="client">General</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 2 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                                // onClick={() => {
                                                                //     setactiveTab(2)
                                                                // }}
                                                                disabled={!(passedSteps || []).includes(2)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">2</div>
                                                                    <div className="client">Lease Term</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 3 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 3 })}
                                                                // onClick={() => {
                                                                //     setactiveTab(3)
                                                                // }}
                                                                disabled={!(passedSteps || []).includes(3)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">3</div>
                                                                    <div className="client">Payments</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        {/* <NavItem
                                                        className={classnames({ current: activeTab === 4 })}
                                                    >
                                                        <NavLink
                                                            className={classnames({ active: activeTab === 4 })}
                                                            // onClick={() => {
                                                            //     setactiveTab(4)
                                                            // }}
                                                            disabled={!(passedSteps || []).includes(4)}
                                                        >
                                                            <div className="d-flex">
                                                                <div className="number">4</div>
                                                                <div className="client">Transition Data</div>
                                                            </div>
                                                        </NavLink>
                                                    </NavItem> */}
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 4 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 4 })}
                                                                disabled={!(passedSteps || []).includes(4)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">4</div>
                                                                    <div className="client">Classification</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 5 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 5 })}
                                                                disabled={!(passedSteps || []).includes(5)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number ">5</div>
                                                                    <div className="client">Allocations</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <Form
                                                    className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    <div className="content clearfix mt-4">
                                                        <TabContent activeTab={activeTab}>
                                                            <TabPane tabId={1}>
                                                                <AddLeaseStepOne formik={validation} clientData={clientData} leaseDraftData={leaseDraftData} mode={mode} />
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <AddLeaseStepTwo formik={validation} leaseDraftData={leaseDraftData} leaseDetails={leaseDetails} />
                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <AddLeaseStepThree formik={validation} clientData={clientData} leaseDraftData={leaseDraftData} termEndPayments={termEndPayments} setTermEndPayments={setTermEndPayments} tab={activeTab} refundableDeposit={refundableDeposit} setRefundableDeposit={setRefundableDeposit} variablePayments={variablePayments} setVariablePayments={setVariablePayments} />
                                                            </TabPane>
                                                            {/* <TabPane tabId={4}>
                                                        <AddLeaseStepFour formik={validation}/>
                                                    </TabPane> */}
                                                            <TabPane tabId={4}>
                                                                <AddLeaseStepFive formik={validation} mode={mode} />
                                                            </TabPane>
                                                            <TabPane tabId={5}>
                                                                <AddLeaseStepSix formik={validation} per={leaseDraftData} mode={mode} />
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="row actions clearfix mt-4 mb-4" style={{ paddingRight: '15px' }}>
                                                        <div style={{ listStyleType: 'none', display: 'flex', justifyContent: sty }}>
                                                            {
                                                                activeTab < 2 &&
                                                                <div style={{ float: 'left' }} className='ms-3'>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => setAddFile(true)}
                                                                        className="btn bg-theme-color"
                                                                    >
                                                                        File Upload
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                (activeTab < 7 && activeTab != 1) &&
                                                                <div
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous ms-3"
                                                                    }
                                                                >
                                                                    <button className="btn bg-theme-color w-md" type='button' onClick={() => { toggleTab(activeTab - 1) }} disabled={loading} >
                                                                        {
                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                        }
                                                                        PREVIOUS
                                                                    </button>
                                                                </div>
                                                            }
                                                            {

                                                                activeTab < 5 &&
                                                                <div style={{ float: 'right' }}
                                                                    className={activeTab === 5 ? "next disabled ms-3" : "next ms-3"}
                                                                >
                                                                    <button type="submit" className="btn bg-theme-color w-md" disabled={loading} >
                                                                        {
                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                        }
                                                                        NEXT
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                activeTab === 5 &&
                                                                <div
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                                    }
                                                                >
                                                                    <button type="submit" className="btn bg-theme-color w-md ms-3" disabled={loading} >
                                                                        {
                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                        }
                                                                        SUBMIT
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
            <UploadFiles selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} addFile={addFile} toggleModel={toggleModel} />
            <Modal
                size="lg"
                isOpen={nextPreviewOpen}
                toggle={() => {
                    toggleNextPreview();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Preview
                    </h5>
                    <button
                        onClick={() => {
                            setNextPreviewOpen(false);
                            setActiveTab1('2')
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card>
                        <CardBody>
                            <Row>
                                <Nav tabs>
                                    {refundableDeposit && refundableDeposit.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab1 === "1",
                                                })}
                                                onClick={() => {
                                                    setActiveTab1("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Refundable Deposit</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    <NavItem style={{ textAlign: 'left' }}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab1 === "2",
                                            })}
                                            onClick={() => {
                                                setActiveTab1("2");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block font-size-16">Lease Payments</span>
                                        </NavLink>
                                    </NavItem>
                                    {termEndPayments && termEndPayments.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab1 === "3",
                                                })}
                                                onClick={() => {
                                                    setActiveTab1("3");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Extension Payments</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {variablePayments && variablePayments.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "4",
                                                })}
                                                onClick={() => {
                                                    setActiveTab1("4");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Variable Lease Payments</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                </Nav>
                                <TabContent
                                    activeTab={activeTab1}
                                    className="p-3 text-muted"
                                >
                                    <TabPane tabId="1">
                                        {refundableDeposit && refundableDeposit.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {refundableDeposit && refundableDeposit.length > 0 && refundableDeposit.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-align-left">{data.type}</td>
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal2)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {sortedPayments && sortedPayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {sortedPayments && sortedPayments.length > 0 && sortedPayments.map((data, i) => {
                                                                            if (data.type != 'Refundable Deposit') {
                                                                                return <tr key={data.type + i}>
                                                                                    <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                    <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                    <td className="text-align-left">{data.type}</td>
                                                                                </tr>
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {termEndPayments && termEndPayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {termEndPayments && termEndPayments.length > 0 && termEndPayments.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-align-left">{data.type}</td>
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal1)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {variablePayments && variablePayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th className="text-center">Date</th>
                                                                            <th className="text-center">Category</th>
                                                                            <th>Estimated Amount</th>
                                                                            <th>Actual Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {variablePayments && variablePayments.length > 0 && variablePayments.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-center">{data.category ? data.category : ''}</td>
                                                                                <td scope="row">$ {data.amount ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                <td scope="row">{data.actual_amount && data.is_processed ? `$${(Number(data.actual_amount)).toFixed(2)}` : ''}</td>
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal3)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                </TabContent>
                            </Row>
                            {/* <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {(Number(totalVal)).toFixed(2)}</h5> */}
                            <div style={{ float: 'right' }} className="next ms-3">
                                <button type="button" className="btn bg-theme-color w-md" disabled={loading} onClick={() => {
                                    setNextPreviewOpen(false);
                                    toggleTab(activeTab + 1);
                                }} >
                                    {
                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    }
                                    CONFIRM PAYMENTS
                                </button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            <Modal
                isOpen={shortTermOpen}
                toggle={() => {
                    setShortTermOpen(false);
                }}
                centered
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Short Term</h5>
                <button
                    type="button"
                    onClick={()=>{setShortTermOpen(false)}}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3">
                    Do you want to mark this lease as short term lease ?
                </div>
                <div className="modal-footer mt-3">
                    <button type="button" className="btn btn-lbusers" onClick={()=>{
                        validation.setFieldValue(`is_short_term`,false)
                        setactiveTab(3)
                        setShortTermOpen(false);
                    }}>
                        No
                    </button>
                    <button type="submit" className="btn btn-lbusers" onClick={()=>{
                        validation.setFieldValue(`is_short_term`,true)
                        validation.setFieldValue(`classify`,'operating')
                        setactiveTab(3)
                        setShortTermOpen(false);
                    }}>
                        Yes
                    </button>
                </div>
            </div>
            </Modal>
        </Layout>
    )
}

export default ModifyLease
